import type Splide from '@splidejs/splide';
import { defineModule } from '@/js/utils/helpers';

const splides: Splide[] = [];

const getElements = () => ({
  splideElements: document.querySelectorAll<HTMLElement>(
    '.content-block--specialists .splide',
  ),
});

export default defineModule(
  async () => {
    const { splideElements } = getElements();

    if (!splideElements.length) return;

    const { Splide } = await import('@splidejs/splide');

    splideElements.forEach((splideElement) => {
      const splide = new Splide(splideElement, {
        type: 'loop',
        autoWidth: true,
        pagination: false,
        gap: '4rem',
        focus: 'center',
      });
      splides.push(splide.mount());
    });
  },
  () => {
    while (splides.length > 0) {
      splides.pop()?.destroy(true);
    }
  },
);
