import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';
import { defineModule, nextTick } from '../utils/helpers';
import { Overlay, hasOpenOverlay, toggleOverlay } from '../utils/overlays';

const getElements = () => ({
  menuElement: document.querySelector<HTMLElement>('header.menu'),
  menuTogglerElement: document.querySelector<HTMLElement>('.menu__toggler'),
});

export const toggleMenu = (force?: boolean) => {
  const { menuElement, menuTogglerElement } = getElements();
  if (!menuElement || !menuTogglerElement) return;

  toggleOverlay(
    Overlay.MENU,
    menuElement.classList.toggle('menu--open', force),
  );

  menuTogglerElement.ariaExpanded = `${hasOpenOverlay(Overlay.MENU)}`;
};

const onMenuTogglerClick = () => toggleMenu();

const navbarBreakpointChecker = () => {
  const { menuElement } = getElements();
  if (!menuElement) return;

  menuElement.classList.add('menu--no-transitions');
  toggleMenu(false);

  nextTick(() => {
    menuElement.classList.remove('menu--no-transitions');
  });
};

export default defineModule(
  () => {
    const { menuTogglerElement } = getElements();
    menuTogglerElement?.addEventListener('click', onMenuTogglerClick);
    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener(
      'change',
      navbarBreakpointChecker,
      { passive: true },
    );
  },
  () => {
    const { menuTogglerElement } = getElements();
    menuTogglerElement?.removeEventListener('click', onMenuTogglerClick);
    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      navbarBreakpointChecker,
    );
  },
);
